import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components/shared/layout';
import { Page } from '../components/shared/Page';
import { Banner } from '../components/shared/Banner';

const StyledContactUsPage = styled(Layout)`
  text-align: center;
`;

const ContactUsPage = () => (
  <StyledContactUsPage headerTransparent>
    <Page>
      <Banner color="#f1593c" />
    </Page>
  </StyledContactUsPage>
);

export default ContactUsPage;
